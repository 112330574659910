(function () {
  "use strict";

  window.onload = () => {
    const wow = new WOW({ scrollContainer: "#home" });
    wow.init();

    const domparser = new DOMParser();

    // Hide loader
    document.getElementById("preloader").classList.add("hidden");
    document.getElementById("hero-section").classList.remove("hidden");

    // Btn event listener
    const shortenBtn = document.getElementById("shorten-url");
    const urlInput = document.getElementById("url-input");
    const copyButton = document.getElementById("copy-url");

    // Error message
    const errorText = document.getElementById("error-txt");
    const errorElement = document.getElementById("error-msg");

    // Try
    const tryBelowBtn = document.getElementById("try-below");

    // Magic element
    const magicElement = document.getElementById("shorten-magic");

    // Success message
    const successTxt = document.getElementById("success-txt");
    const successElement = document.getElementById("success-msg");

    // Button loader
    const buttonLoader = document.getElementById("button-loader");
    const buttonText = document.getElementById("button-txt");

    // Claim button
    const claimUrlsButton = document.getElementById("claim-urls");
    const claimButton = document.getElementById("button-loader-claim");
    const claimButtonText = document.getElementById("button-txt-claim");

    // Url popup
    const urlsPopupButton = document.getElementById("open-urls-popup");
    const urlsCount = document.getElementById("urls-count");
    const wrapper = document.getElementById("wrapper");
    const urlsPopup = document.getElementById("urls-popup");
    const myUrls = document.getElementById("my-url-list");
    const closePopupButton = document.getElementById("close-popup");

    // Import 
    const claimInfo = document.getElementById("claim-info");
    const claimOption = document.getElementById("claim-option");
    const importKey = document.getElementById("import-key");
    const copyCode = document.getElementById("copy-code");
    const claimDone = document.getElementById("claim-done");

    // Create Account
    const createAccountButton = document.getElementById("create-free-account");

    // Window Scroll
    const aboutSecton = document.getElementById('about');


    const showCopyButton = () => {
      shortenBtn.classList.add("hidden");
      copyButton.classList.remove("hidden");
    }

    const hideCopyButton = () => {
      shortenBtn.classList.remove("hidden");
      copyButton.classList.add("hidden");
    }

    const isValidUrl = (url) => {
      const urlRegex =
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
      return urlRegex.test(url);
    };

    const displayError = (message = "Please enter a valid url") => {
      errorText.innerHTML = message;
      errorElement.classList.remove("translate-y-72");
      magicElement.classList.add("shake-anim");
      setTimeout(() => {
        errorElement.classList.add("translate-y-72");
        magicElement.classList.remove("shake-anim");
      }, 3000);
    }

    const displaySuccess = (message = "URL Copied") => {
      successTxt.innerHTML = message;
      successElement.classList.remove("translate-y-72");
      setTimeout(() => {
        successElement.classList.add("translate-y-72");
      }, 3000);
    }

    const startLoading = () => {
      buttonText.classList.add("hidden");
      buttonLoader.classList.remove("hidden");
    }

    const stopLoading = () => {
      buttonText.classList.remove("hidden");
      buttonLoader.classList.add("hidden");
    }

    const startLoadingClaim = () => {
      claimButtonText.classList.add("hidden");
      claimButton.classList.remove("hidden");
    }

    const stopLoadingClaim = () => {
      claimButtonText.classList.remove("hidden");
      claimButton.classList.add("hidden");
    }

    const isAlreadyShortened = (url) => {
      const data = localStorage.getItem("shortchey");
      if (data) {
        const parsedData = JSON.parse(data);
        const found = parsedData.find((item) => item.long_url === url);
        if (found) {
          return found.short_url;
        }
      }
      return false;
    }

    const isFreeUrlsCapReached = () => {
      const data = localStorage.getItem("shortchey");
      if (data) {
        const parsedData = JSON.parse(data);
        return parsedData.length >= 5;
        // const today = new Date().getTime();
        // const filteredData = parsedData.filter((item) => {
        //   return today - item.date < 86400000;
        // });
        // if (filteredData.length >= 5) {
        //   return true;
        // }
      }
      return false;
    }

    const hasAnyShortenedUrl = () => {
      const data = localStorage.getItem("shortchey");
      if (data) {
        const parsedData = JSON.parse(data);
        return parsedData.length > 0;
      }
      return false;
    }

    const setDataInLocalStore = (_urlData) => {
      const data = localStorage.getItem("shortchey");
      const urlData = { ..._urlData, date: new Date().getTime() };
      if (data) {
        const parsedData = JSON.parse(data);
        parsedData.push(urlData);
        localStorage.setItem("shortchey", JSON.stringify(parsedData));
      }
      else {
        localStorage.setItem("shortchey", JSON.stringify([urlData]));
      }
    }

    const shortenSuccess = (shortUrl, inputElement) => {
      stopLoading();
      showCopyButton();
      displaySuccess();
      urlInput.value = shortUrl;
      // inputElement.select();
      // document.execCommand("copy");
      navigator.clipboard.writeText(shortUrl);
    }

    const renderMyUrls = () => {
      const data = localStorage.getItem("shortchey");
      const myUrlsHTML = document.createDocumentFragment();
      if (data) {
        const parsedData = JSON.parse(data);
        const urlsLength = parsedData.length;
        if (urlsLength > 0) {
          urlsCount.innerHTML = urlsLength;
          urlsPopupButton.classList.remove("hidden");
          for (let i = 0; i < parsedData.length; i++) {
            const item = parsedData[i];
            const itemElement = `<div class="url-item my-2 flex flex-col items-start local-url-item rounded-lg">
          <div class="text-lg break-all font-bold">${item.title || item.short_url}</div>
          <div class="text-sm break-all">${item.long_url}</div>
          <div class="text-sm font-bold inline-block text-white py-2 px-4 bg-secondary rounded-lg my-2">${item.short_url}</div>
        </div>`;
            myUrlsHTML.appendChild(domparser.parseFromString(itemElement, 'text/html').body.childNodes[0])
          }
          claimOption?.classList?.remove("hidden");
        }
      }
      myUrls.innerHTML = "";
      myUrls.append(myUrlsHTML);
    }

    const processUrl = async (url, inputElement) => {
      startLoading();
      const alreadyShortened = await isAlreadyShortened(url);
      if (alreadyShortened) {
        shortenSuccess(alreadyShortened, inputElement);
        return;
      }
      // const data = {
      //   success: true,
      //   data: {
      //     short_url: "https://shr.onl/123456"
      //   }
      // }
      const response = await fetch("https://labs.shortchey.com/api/v1/url/shorten", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ long_url: url }),
      });
      const data = await response.json();
      if (data.success && data?.data?.short_url) {
        const { short_url } = data.data;
        setDataInLocalStore(data.data);
        claimOption?.classList?.remove("hidden");
        setTimeout(() => {
          shortenSuccess(short_url, inputElement);
          renderMyUrls();
        }, 300);
      }
    }

    const openPopup = (showClaimOption = false) => {
      wrapper.classList.remove("hidden");
      if (showClaimOption) {
        claimOption?.classList?.remove("hidden");
      }
      setTimeout(() => {
        urlsPopup.classList.remove("translate-y-36");
      }, 20);
    }

    const closePopup = () => {
      urlsPopup.classList.add("translate-y-36");
      setTimeout(() => {
        wrapper.classList.add("hidden");
      }, 100);
    }

    urlInput.addEventListener("input", (e) => {
      if (e.target.value === "" || e.target.value.indexOf("shr.onl") === -1) {
        hideCopyButton();
      }
    });

    urlInput.addEventListener("keyup", (e) => {
      if (e.key === "Enter") {
        shortenBtn.click();
      }
    });

    copyButton.addEventListener("click", (e) => {
      // urlInput.select();
      // document.execCommand("copy");
      // inputElement.value = shortUrl;
      // inputElement.select();
      // document.execCommand("copy");
      navigator.clipboard.writeText(urlInput.value);
      displaySuccess();
    });

    shortenBtn.addEventListener("click", (e) => {
      const url = urlInput.value;
      if (isValidUrl(url)) {
        isFreeUrlsCapReached() ? displayError("You have reached guest user limit, Please login to create more short urls") :
          setTimeout(() => {
            processUrl(url, urlInput);
          }, 300);
      }
      else {
        displayError("Please enter a valid URL");
      }
    })

    urlsPopupButton.addEventListener("click", (e) => {
      openPopup();
    })

    closePopupButton.addEventListener("click", (e) => {
      closePopup();
    })

    tryBelowBtn.addEventListener("click", (e) => {
      magicElement.classList.add("shake-anim");
      setTimeout(() => {
        magicElement.classList.remove("shake-anim");
      }, 500);
    });

    // claimUrlsButton.addEventListener("click", (e) => {
    //   startLoadingClaim();
    //   setTimeout(() => {
    //     claimInfo.classList.add("hidden");
    //     claimDone.classList.remove("hidden");
    //     stopLoadingClaim();
    //   }, 500);
    // });

    // copyCode.addEventListener("click", (e) => {
      // codeInput.select();
      // document.execCommand("copy");
    //   navigator.clipboard.writeText("CD 12 4T");
    //   displaySuccess("Code copied to clipboard");
    // });

    // const gtag_report_conversion = () => {
    //    callback = () => {
    //     window.location.href = "https://app.shortchey.com/auth";
    //   };

    //   gtag('event', 'conversion', {
    //     'send_to': 'AW-11136223072/RqKkCOeUwJUYEOCOlb4p',
    //     'event_callback': () => {
    //       window.location.href = "https://app.shortchey.com/auth";
    //     }
    //   });
    //   return false;
    // }


    createAccountButton.addEventListener("click", (e) => {
      // startLoadingCreateAccount();
      // if (hasAnyShortenedUrl()) {
      //   openPopup(true);
      // } else {
      //   window.location.href = "https://app.shortchey.com/auth";
      // }
      // gtag_report_conversion();
      gtag('event', 'conversion', {
        'send_to': 'AW-11136223072/RqKkCOeUwJUYEOCOlb4p',
        'event_callback': () => {}
      });

      setTimeout(() => {
        window.location.href = "https://app.shortchey.com/auth";
      }, 300);

    });

    renderMyUrls();
  }
})();
